<template>
  <div class="products">
    <div style="background: no-repeat center center / cover" :style="{ backgroundImage: 'url(' + require('@/assets/breadcumb.jpg') + ')' }"
         class="breadcumb-area" >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="breadcumb-title">{{ cat.name }}</h2>
            <ul class="breadcumb-menu">
              <li>
                <router-link to="/">{{ langContent.home }}</router-link>
              </li>
              <li>{{ cat.name }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="blog-area blog-classic-area">
      <div class="container">
        <div class="row flex-lg-row-reverse">


          <div class="col-12 col-lg-8 mb-5">

            <div class="row" >
              <div class="col-12 col-lg-12 mb-5">
                <img class="img" v-if="cat.gorselgizle==0" :src="cat.img" :alt="cat.name">
                <h1 class="mb-4">{{ cat.name }}</h1>
                <p>
                  {{ cat.desc }}
                </p>
                <div class="row" v-if="cat.main != 0">

                <template v-for="item in all">
                <template v-if="cat.id==item.sub">
                      <router-link  :to="'/'+this.$route.params.s+'/'+cat.seo+'/'+item.seo" class="col-md-6">
                        <div class="card w-100">
                          <img class="card-img-top" :src="item.img" :alt="item.name">
                          <div class="card-body">
                            <p class="card-text">
                              {{item.name}}
                            </p>
                          </div>
                        </div>
                      </router-link>
                  </template>
                  </template>
                </div>

              </div>
               <div v-for="(item,index) in cat.galery" class="grid col-md-4 mb-4" @click="openLightboxOnSlide(index+1)">
                 <img :alt="cat.name" :style="( this.cat.galery == null  ? 'display:none;' : '')" :src="item" class="img-fluid">
               </div>
            </div>
          </div>
          <div class=" col-lg-4">
            <aside class="blog-sidebar">
              <div class="category-wrapper">
                <h3 class="text-uppercase">{{ this.$route.params.s }}</h3>
                <ul class="category-items">
                  <template v-for="item in all.slice().reverse()" >
                  <li v-if="item.main != 0">
                    <router-link  :style="[(this.$route.params.c == item.seo  ? 'color:black;' : ''),(this.$route.params.a == item.seo  ? 'color:black;' : '')]"
                                 :to="'/'+this.$route.params.s+'/'+item.seo">{{ item.name }}<span><i class="fa fa-arrow-right"></i></span>
                    </router-link>
                  </li>
                  </template>
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
    <div class="container">

    </div>
    <div class="blog-area blog-page-area mt-4 pt-5">
      <div class="container">

        <div class="row">
          <div v-for="item in products" class="col-lg-4 col-sm-6 col-12">
            <router-link :to="'/urun/'+item.seo" class="blog-single">
              <div class="thumb">
                <img :src="item.img[0]" alt="blog-image">
              </div>
              <div class="content">
                <ul class="blog-meta-top">
                  <li><i class="fa fa-barcode"></i> {{ item.code }}</li>
                </ul>
                <h3>{{ item.title }}</h3>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <FsLightbox
        :slide="slide"
        :sources="galery"
        :toggler="toggler"
    />
  </div>
</template>
<script>
import FsLightbox from "fslightbox-vue/v3";
import axiosCustom from "../../axiosCustom";

export default {
  components: {FsLightbox},
  data() {
    return {
      title: 'Kategori',
      cat: [],
      all: [],
      galery: [],
      subs: null,
      products: [],
      langContent: [],
      toggler: false,
      slide: 1
    }
  },
  created() {
    this.get()
  },
  methods: {
    get: function () {
      axiosCustom
          .get('langfix')
          .then(response => {
            this.langContent = response.data[0]
          })
          .catch(error => {
            console.log(error);
          })
      axiosCustom
          .get(this.$route.params.s + '/' + this.$route.params.c)
          .then(response => {
            this.cat = response.data
              this.galery = [...response.data.galery]
            document.title = 'Delta Plastik | ' + this.cat.name

          })
          .catch(error => {
            console.log(error);
          })
      axiosCustom
          .get(this.$route.params.s)
          .then(response => {
            this.all = response.data
            this.subs = response.data.filter(c => c.sub === this.cat.id)
          })
          .catch(error => {
            console.log(error);
          })
    },
    openLightboxOnSlide: function (number) {
      this.slide = number;
      this.toggler = !this.toggler;
    }
  },
  watch: {
    $route(to, from) {
      this.get()
      console.log(this.galery)
    }
  },
}
</script>
<style>
img[src^="n"],
img[src^="u"],
img[src^="l"]{
  display: none!important;
}
</style>
